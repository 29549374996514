import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import YouTube from 'react-youtube';

const popeVideoIdRegex = /https:\/\/www.youtube.com\/embed\/(.*?)\?/;

const intentions = [
  {
    title: 'True Human Fraternity',
    text:
      'We pray for all those suffering from religious discrimination and persecution; may their own rights and dignity be recognized, which originate from being brothers and sisters in the human family.',
  },
  {
    title: 'Religious Sisters and Consecrated Women',
    text:
      'We pray for religious sisters and consecrated women; thanking them for their mission and their courage; may they continue to find new responses to the challenges of our times.',
  },
  {
    title: 'A Christian Response to Bioethical Challenges',
    text:
      'We pray for Christians facing new bioethical challenges; may they continue to defend the dignity of all human life with prayer and action.',
  },
  {
    title: 'Health Care Workers',
    text:
      'We pray for health care workers who serve the sick and the elderly, especially in the poorest countries; may they be adequately supported by governments and local communities.',
  },
  {
    title: 'Faith-Filled Young People',
    text:
      'We pray for all young people, called to live life to the fullest; may they see in Mary’s life the way to listen, the depth of discernment, the courage that faith generates, and the dedication to service.',
  },
  {
    title: 'Families',
    text:
      'We pray for Christian families around the world; may they embody and experience unconditional love and advance in holiness in their daily lives',
  },
  {
    title: 'Elderly',
    text:
      'We pray for the elderly, who represent the roots and memory of a people; may their experience and wisdom help young people to look towards the future with hope and responsibility.',
  },
  {
    title: 'Small Businesses',
    text:
      'We pray for small and medium sized businesses; in the midst of economic and social crisis, may they find ways to continue operating, and serving their communities.',
  },
  {
    title: 'Abolition of the Death Penalty',
    text:
      'We pray that the death penalty, which attacks the dignity of the human person, may be legally abolished in every country.',
  },
  {
    title: 'A Church Open to Everyone',
    text:
      'We pray for the Church; ever faithful to, and courageous in preaching the Gospel, may the Church be a community of solidarity, fraternity and welcome, always living in an atmosphere of synodality.',
  },
  {
    title: 'Children Who Suffer',
    text:
      'We pray for children who are suffering, especially those who are homeless, orphans, and victims of war; may they be guaranteed access to education and the opportunity to experience family affection.',
  },
  {
    title: 'Volunteer Not-for-Profit Organizations',
    text:
      'We pray that volunteer non-profit organizations committed to human development find people dedicated to the common good and ceaselessly seek out new paths to international cooperation.',
  },
];

class PopeIntentionHard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Loading',
      text: 'Loading',
      videoId: null,
    };
  }

  componentDidMount() {
    axios
      .get('https://cors-veym.herokuapp.com/https://thepopevideo.org/')
      .then(({ data }) => {
        this.setState({ videoId: data.match(popeVideoIdRegex)[1] });
      })
      .catch(error => console.warn('Error getting pope video: ' + error));
    axios
      .get(
        'https://cors-veym.herokuapp.com/http://popesprayerusa.net/popes-intentions/'
      )
      .then(({ data }) => {
        const monthName = moment().format('MMMM').toUpperCase();
        const regex = new RegExp(
          '<strong>' +
            monthName +
            '</strong>' +
            '([^\0]*?)<strong>(.*?)</strong><br />\n' +
            '(.*?)</p>'
        );
        // console.log(data.match(regex));
        this.setState({
          title: data.match(regex)[2],
          text: data.match(regex)[3],
        });
      })
      .catch(error => console.warn('Error getting pope text: ' + error));
  }

  render() {
    const { videoId } = this.state;
    return (
      <div>
        <br />
        <br />
        {/* <h5 style={{ textAlign: 'left' }}>{title}</h5>
        <p style={{ textAlign: 'left' }}>{text}</p> */}

        {/* ******* TEMPORARY FIX ******** */}
        {/* sometimes the parsing logic doesn't work right - maybe october through january? */}
        {/* <h5 style={{ textAlign: 'left' }}>Sacrament of Reconciliation</h5>
        <p style={{ textAlign: 'left' }}>
          Let us pray that we may experience the sacrament of reconciliation
          with renewed depth, to taste the infinite mercy of God.
        </p> */}
        {/* ***********  ******** */}

        <h5 style={{ textAlign: 'left' }}>
          {intentions[moment().format('M') - 1].title}
        </h5>
        <p style={{ textAlign: 'left' }}>
          {intentions[moment().format('M') - 1].text}
        </p>

        <br />
        <br />
        {videoId != null ? (
          <YouTube videoId={videoId} opts={{ width: '100%' }} />
        ) : (
          <div>
            Please check <a href="https://thepopevideo.org">here</a> for the
            latest Pope's videos
          </div>
        )}
      </div>
    );
  }
}

export default PopeIntentionHard;
