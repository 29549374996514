import React from 'react';
import styles from './KhauHieuBar.module.css';

const KhauHieuBar = () => (
  <div className={styles.khauhieubar}>
    <div className={styles.cauNguyen}>
      <div className={styles.cauNguyenText}>
        <h5>Cầu Nguyện</h5>
      </div>
    </div>
    <div className={styles.ruocLe}>
      <div className={styles.ruocLeText}>
        <h5>Rước Lễ</h5>
      </div>
    </div>
    <div className={styles.hySinh}>
      <div className={styles.hySinhText}>
        <h5>Hy Sinh</h5>
      </div>
    </div>
    <div className={styles.tongDo}>
      <div className={styles.tongDoText}>
        <h5>Làm Việc Tông Đồ</h5>
      </div>
    </div>
  </div>
);

export default KhauHieuBar;
