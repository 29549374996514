import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { Row, Col, Container, UncontrolledAlert } from 'reactstrap';
import moment from 'moment';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import PopeIntentionHard from '../components/PopeIntentionHard';
import NewsCard from '../components/NewsCard/NewsCard';
import styles from '../css/home.module.css';
import KhauHieuBar from '../components/KhauHieuBar/KhauHieuBar';
import HomeImage from '../components/GatsbyImageComponents/HomeImage';
import AffiliationBar from '../components/AffiliationBar/AffiliationBar';
import TitleBar from '../components/TitleBar/TitleBar';
import GospelSwitcher from '../components/GospelSwitcher/GospelSwitcher';
import FrontpagePost from '../components/FrontPagePost/FrontpagePost';
import axios from 'axios';
import YouTube from 'react-youtube';
import WeeklyGospelVideos from '../components/WeeklyGospelVideos/WeeklyGospelVideos';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsVideos: [],
      newsReady: false,
      newsLoadFailed: false,
    };
  }

  componentDidMount() {
    axios
      .get(
        'https://www.googleapis.com/youtube/v3/playlistItems?part=id%2C%20contentDetails&playlistId=PLieCJC88WbxFi4vUl83Fujhr-WejNthSo&key=AIzaSyA97Oqlc3JqnAFB9pV4PT-roR9O4q8iXII&maxResults=50'
      )
      .then(res => {
        const { data } = res;
        const videoList = data.items
          .map(item => ({
            videoId: item.contentDetails.videoId,
            videoPublishedAt: item.contentDetails.videoPublishedAt,
          }))
          .sort((video1, video2) => {
            return moment(video1.videoPublishedAt).isAfter(
              moment(video2.videoPublishedAt) ? -1 : 1
            );
          });
        try {
          this.setState({
            newsVideos: videoList,
            newsReady: true,
          });
        } catch {
          console.log('something wrong');
        }
      })
      .catch(error => {
        this.setState({ newsLoadFailed: true });
        console.log(error);
      });
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allWpPost;
    const nonHomepagePosts = posts
      .filter(post => {
        return (
          (post.node.categories.length === 0 ||
            post.node.categories.nodes[0]?.slug !== 'updates') &&
          (post.node.tags.nodes.length === 0 ||
            post.node.tags.nodes[0]?.slug !== 'frontpage')
        );
      })
      .slice(0, 3);

    const { newsVideos, newsLoadFailed, newsReady } = this.state;

    const newsVideosSectionLoading = !newsLoadFailed ? (
      <div>
        <TitleBar>VEYM News</TitleBar>
        <Container>
          <div>
            <br />
            Loading News Broadcasts..
            <div className={styles.spinner}>
              <div className={styles.rect1} />
              <div className={styles.rect2} />
              <div className={styles.rect3} />
              <div className={styles.rect4} />
              <div className={styles.rect5} />
            </div>
          </div>
        </Container>
      </div>
    ) : null;

    return (
      <Layout>
        <Helmet
          meta={[
            {
              name: 'description',
              content: `Official Website of the Vietnamese Eucharistic Youth Movement in the USA (Phong Trào Thiếu Nhi Thánh Thể Việt Nam tại Hoa Kỳ), a Catholic non-profit youth organization. Recent TNTT announcements, news, events, resources, and other information.`,
            },
          ]}
        />
        {/* <CarouselPage /> */}

        <div className={styles.coverImageContainer}>
          <HomeImage />
          <div className={styles.coverImageTextContainer}>
            <div className={styles.coverImageText}>
              Prayer
              <br />
              Eucharist
              <br />
              Sacrifice
              <br />
              Apostolic Work
            </div>
          </div>
        </div>

        <KhauHieuBar />
        <section style={{ height: '5px' }} />
        <br />
        {/* <Container>
          <UncontrolledAlert color="info">
            VEYM is committed to serving its members during the COVID-19
            pandemic. Follow the{' '}
            <a
              href="https://www.facebook.com/pttnttvn.hk"
              target="_blank"
              rel="noopener noreferrer"
            >
              VEYM Facebook
            </a>{' '}
            for updates regarding online resources to help the youth continue to
            live the faith and pray while unable to fully sinh hoat in person.
            Please follow guidance from your Diocese and local authorities
            regarding gatherings and activities, and continue to pray with us as
            we go through these challenging times.
          </UncontrolledAlert>
        </Container> */}

        <Container>
          <UncontrolledAlert color="info">
            This is the old VEYM website at old.veym.net. If you are at
            veym.net, please clear your browser cache and refresh the page.
          </UncontrolledAlert>
        </Container>

        <TitleBar>Welcome to VEYM</TitleBar>

        <Container>
          <br />
          <p>
            The Vietnamese Eucharistic Youth Movement (VEYM) is a non-profit
            faith based organization with aims to train youth to become
            well-rounded individuals within society and ideal Christians. The+
            VEYM is a branch of the Apostleship of Prayer, now known as the
            Pope’s Worldwide Prayer Network, an official pontifical service
            under the Holy See. At present, there are over 130 VEYM Chapters at
            parishes across the US with approximately 2500 youth leaders and
            over 25,000 youth members.
          </p>
        </Container>

        <br />

        <br />

        <FrontpagePost />

        <br />
        {newsReady ? (
          <div>
            <TitleBar>VEYM News</TitleBar>
            <br />
            <Container>
              <YouTube
                videoId={newsVideos[0].videoId}
                // opts={{ width: '100%' }}
              />
              <br />
            </Container>
          </div>
        ) : (
          newsVideosSectionLoading
        )}

        <TitleBar>Recent News</TitleBar>
        <br />

        <Container>
          <div>
            <Row className="justify-content-center">
              {nonHomepagePosts.map(({ node: post }) => (
                <Col
                  key={post.id}
                  lg={4}
                  md={12}
                  sm={12}
                  align="center"
                  style={{ padding: '.25em .25em' }}
                >
                  <NewsCard post={post} />
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center">
              <Link className="" to="/news">
                View All News
              </Link>
            </Row>
          </div>
        </Container>

        <br />
        <Container>
          <Row align="center">
            <Col
              md={6}
              sm={12}
              style={{ border: '1px solid #eaecee', padding: '2em 2em' }}
            >
              <GospelSwitcher />
            </Col>
            <Col
              md={6}
              sm={12}
              style={{ border: '1px solid #eaecee', padding: '2em 2em' }}
            >
              <h4>Pope's Monthly Intention</h4>
              {moment().format('MMMM YYYY')}
              <PopeIntentionHard />
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <AffiliationBar />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query HomepageQuery {
    allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          id
          slug
          categories {
            nodes {
              slug
            }
          }
          status
          excerpt
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          uri
          tags {
            nodes {
              slug
            }
          }
        }
      }
    }
  }
`;
