import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import styles from './GospelSwitcher.module.css';
import axios from 'axios';
import moment from 'moment-timezone';
import ReactPlaceholder from 'react-placeholder';
import UpcomingWeeklyGospel from '../UpcomingWeeklyGospel/UpcomingWeeklyGospel';
// import DailyGospelVietStandalone from "../DailyGospel/DailyGospelVietStandalone";
import DailyGospelViet from '../DailyGospel/DailyGospelViet';
import Toggle from 'react-toggle-component';
import '../../css/toggleComponent.css';
import { useQuery } from 'react-query';

const GospelSwitcher = () => {
  const [activeTab, setActiveTab] = useState('dailyGospel');
  const [dailyGospelLangViet, setDailyGospelLangViet] = useState(false);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleLang = () => {
    setDailyGospelLangViet(!dailyGospelLangViet);
  };

  const getNextSunday = () => {
    const dayINeed = 0; // for Sunday
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(dayINeed);
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  };

  // get daily gospel english
  const dailyDateString = moment().format('YYYYMMDD');
  const {
    isLoading: dailyLoading,
    error: dailyError,
    data: dailyData,
  } = useQuery(
    'dailyGospelEn',
    async () => {
      const res = await axios({
        method: 'get',
        url: `https://cors-veym.herokuapp.com/http://universalis.com/${dailyDateString}/jsonpmass.js`,
      });

      const data = JSON.parse(res.data.substring(20, res.data.length - 3));
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  // get upcoming sunday gospel english
  const sundayDateString = getNextSunday().format('YYYYMMDD');
  const {
    isLoading: sundayLoading,
    error: sundayError,
    data: sundayData,
  } = useQuery(
    'sundayGospel',
    async () => {
      const res = await axios({
        method: 'get',
        url: `https://cors-veym.herokuapp.com/http://universalis.com/${sundayDateString}/jsonpmass.js`,
      });

      const data = JSON.parse(res.data.substring(20, res.data.length - 3));
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  // get upcoming sunday lesson
  const {
    isLoading: lessonLoading,
    error: lessonError,
    data: lessonData,
  } = useQuery(
    'upcomingLesson',
    async () => {
      const res = await axios.get(
        'https://cors-veym.herokuapp.com/https://cms.veym.net/items/weekly_gospel_lessons?sort=sunday_date'
      );

      const {
        data: { data },
      } = res;
      return data.find(({ sunday_date }) =>
        moment(sunday_date).isSame(getNextSunday(), 'day')
      );
    },
    { refetchOnWindowFocus: false }
  );

  // get daily gospel viet

  const now = moment();
  const usTime = moment.tz(now, 'America/Chicago');
  const vnTime = moment.tz(now, 'Asia/Ho_Chi_Minh');

  const vnDaySame = vnTime.format('D') === usTime.format('D');
  const selDateValue = vnDaySame ? '1' : '0';

  const { isLoading: vietLoading, error: vietError, data: vietData } = useQuery(
    'dailyGospelViet',
    async () => {
      const res = await axios({
        method: 'post',
        url: `https://cors-veym.herokuapp.com/https://ktcgkpv.org/readings/mass-reading`,
        data: `seldate=${selDateValue}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });

      const { data } = res.data;
      const massReading = data.mass_reading;

      const vietGospels = massReading.map(gospel => {
        console.log(gospel);
        return {
          dailyTitle: gospel.date_info.daily_title,
          displayText: gospel.display_text,
          indexing: gospel.gospel[0]?.INDEXING,
          epitomize: gospel.gospel[0]?.EPITOMIZE,
          content: gospel.gospel[0]?.CONTENT,
          isSpecial: gospel.is_special,
          specialContent: gospel?.special_content,
        };
      });

      return vietGospels;
    },

    { refetchOnWindowFocus: false }
  );

  const dailyGospelTab = (
    <TabPane tabId="dailyGospel">
      <br />
      <ReactPlaceholder type="text" rows={8} ready={!dailyLoading}>
        <div className={styles.dailyTitle}>
          <h4>Daily Gospel Reading</h4>
        </div>
        {moment().format('dddd, MMMM Do, YYYY')}
        <br />
        <Toggle
          label="English"
          labelRight="Tiếng Việt"
          mode="select"
          checked={dailyGospelLangViet}
          onToggle={toggleLang}
          style={{ marginTop: '8px' }}
        />
        {!dailyGospelLangViet ? (
          <div className={styles.container} style={{ textAlign: 'left' }}>
            <br />
            <h6>"{dailyData && dailyData.Mass_G.heading}"</h6>
            {dailyData && dailyData.Mass_G.source}
            <br />
            <div
              className={styles.dailyGospelText}
              dangerouslySetInnerHTML={{
                __html: dailyData && dailyData.Mass_G.text,
              }}
            />
          </div>
        ) : (
          <DailyGospelViet
            gospels={vietData ? vietData : []}
            ready={!vietLoading}
          />
        )}
      </ReactPlaceholder>
    </TabPane>
  );

  const upcomingSundayGospelTab = (
    <TabPane tabId="upcomingSundayGospel">
      <br />

      <ReactPlaceholder type="text" rows={16} ready={!lessonLoading}>
        {lessonData !== undefined ? (
          <UpcomingWeeklyGospel
            upcomingSundayLesson={lessonData}
            dateString={getNextSunday().format('dddd, MMMM Do, YYYY')}
            colMd={12}
            colLg={6}
          />
        ) : (
          <div>
            <br />
            <br />
            <br />
            <Spinner color="secondary" size="lg" />
          </div>
        )}
      </ReactPlaceholder>
    </TabPane>
  );

  const sundayGospelTab = (
    <TabPane tabId="sundayGospel">
      <br />
      <ReactPlaceholder type="text" rows={8} ready={!sundayLoading}>
        <h4>Sunday Gospel Reading</h4>
        {getNextSunday().format('dddd, MMMM Do, YYYY')}
        <div className={styles.container} style={{ textAlign: 'left' }}>
          <br />
          <h6>"{sundayData && sundayData.Mass_G.heading}"</h6>
          {sundayData && sundayData.Mass_G.source}
          <br />
          <div
            className={styles.sundayGospelText}
            dangerouslySetInnerHTML={{
              __html: sundayData && sundayData.Mass_G.text,
            }}
          />
        </div>
      </ReactPlaceholder>
    </TabPane>
  );

  return (
    <div className={styles.gospelSwitcher}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === 'dailyGospel' ? 'active' : ''}
            onClick={() => {
              toggleTab('dailyGospel');
            }}
          >
            Daily Gospel
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'sundayGospel' ? 'active' : ''}
            onClick={() => {
              toggleTab('sundayGospel');
            }}
          >
            Sunday Gospel
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'upcomingSundayGospel' ? 'active' : ''}
            onClick={() => {
              toggleTab('upcomingSundayGospel');
            }}
          >
            Gospel Lesson
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {dailyGospelTab}
        {sundayGospelTab}
        {upcomingSundayGospelTab}
      </TabContent>
    </div>
  );
};

export default GospelSwitcher;
