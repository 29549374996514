import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './NewsCard.module.css';

const NewsCard = ({ post }) => {
  const { date, featuredImage, title, slug } = post;

  return (
    <div>
      <Card className={styles.newsCard}>
        <Link to={`/news/${slug}`}>
          <div className={styles.cardImgContainer}>
            {featuredImage.node.localFile != null && (
              <Img
                fluid={featuredImage.node.localFile.childImageSharp.fluid}
                style={{ height: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
              />
            )}
          </div>
        </Link>
        <CardBody>
          <CardTitle>
            <Link to={`/news/${slug}`}>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </CardTitle>
          <CardSubtitle>
            <small className={styles.date}>{date}</small>
          </CardSubtitle>
        </CardBody>
      </Card>
    </div>
  );
};
export default NewsCard;
