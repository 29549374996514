import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HomeImage = () => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        imageOne: file(relativePath: { eq: "monstrancetntt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.imageOne.childImageSharp.fluid}
        style={{ maxHeight: '88vh' }}
        imgStyle={{ objectFit: 'cover' }}
        alt="TNTTMonstrance"
      />
    )}
  />
);

export default HomeImage;
