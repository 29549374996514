import React, { Component } from 'react';
import ReactPlaceholder from 'react-placeholder';
import styles from './dailyGospel.module.css';
import { Input } from 'reactstrap';

class DailyGospelViet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gospelIndex: 0,
    };
  }

  handleChange = (prop, value) => {
    this.setState({ [prop]: value });
  };

  render() {
    const { gospelIndex } = this.state;
    const { gospels, ready } = this.props;

    console.log(gospels);

    return (
      <ReactPlaceholder type="text" rows={8} ready={ready}>
        {gospels.length !== 0 ? (
          <div>
            <div className={styles.container} style={{ textAlign: 'left' }}>
              <br />
              {gospels.length > 1 && (
                <div>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={evt =>
                      this.handleChange('gospelIndex', evt.target.value)
                    }
                    value={gospelIndex}
                  >
                    {gospels.map((gospel, index) => (
                      <option value={index} key={gospel.displayText}>
                        {gospel.displayText}
                      </option>
                    ))}
                  </Input>
                  <br />
                </div>
              )}

              {gospels[gospelIndex].isSpecial ? (
                <h6>{gospels[gospelIndex].displayText}</h6>
              ) : (
                <h6>{`"${gospels[gospelIndex].epitomize}"`}</h6>
              )}
              {/* <h6>"{gospels[gospelIndex].epitomize}"</h6> */}
              {gospels[gospelIndex].indexing}
              <br />
              <div
                className={styles.dailyGospelText}
                style={{ height: gospels.length > 1 ? '430px' : '480px' }}
                dangerouslySetInnerHTML={{
                  __html: gospels[gospelIndex].isSpecial
                    ? gospels[gospelIndex].specialContent
                    : gospels[gospelIndex].content,
                }}
              />
            </div>
          </div>
        ) : (
          <div />
        )}
      </ReactPlaceholder>
    );
  }
}

export default DailyGospelViet;
