import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './AffiliationBar.module.css';

const AffiliationBar = props => (
  <StaticQuery
    query={graphql`
      query AffiliationBarImageQuery {
        imgAP: file(relativePath: { eq: "popeprayernetwork-300x272.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        imgEYM: file(relativePath: { eq: "EYM.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        imgClickToPray: file(relativePath: { eq: "clicktopray.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.row}>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={12} md={2} lg={3} className="text-center ">
              <div className={styles.buttonContainer}>
                {/* <h4 className={styles.textCentered}>Our Affiliations: </h4> */}

                <Link to="/stewardship/donate">
                  <Button size="lg" color="secondary" className={styles.button}>
                    DONATE
                  </Button>
                </Link>
                <br />
                <br />
                <Link className={styles.link} to="/stewardship/donate">
                  Help support VEYM
                </Link>
              </div>
            </Col>
            <Col sm={12} md={3} className="justify-content-center">
              <div className={styles.imgContainer}>
                <a
                  href="https://www.popesprayer.va/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={data.imgAP.childImageSharp.fixed}
                    style={{
                      maxHeight: '90px',
                      position: 'static',
                    }}
                    imgStyle={{
                      maxHeight: '90px',
                      objectFit: 'contain',
                    }}
                    alt="PWPN"
                  />
                </a>
                <br />
                <a
                  href="https://www.popesprayer.va/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  Pope's Worldwide Prayer Network
                </a>
              </div>
              {/* <MediaQuery query="(max-width: 990px)">
                <div>
                  &nbsp;
                  <br />
                </div>
              </MediaQuery> */}
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.imgContainer}>
                <a
                  href="https://www.facebook.com/mej.internacional"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={data.imgEYM.childImageSharp.fixed}
                    style={{
                      maxHeight: '90px',
                      position: 'static',
                    }}
                    imgStyle={{
                      maxHeight: '90px',
                      objectFit: 'contain',
                    }}
                    alt="EYM"
                  />
                </a>
                <br />
                <a
                  href="https://www.facebook.com/mej.internacional"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  EYM International
                </a>
              </div>
              {/* <MediaQuery query="(max-width: 990px)">
                <div>
                  &nbsp;
                  <br />
                </div>
              </MediaQuery> */}
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.imgContainer}>
                <a
                  href="https://clicktopray.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={data.imgClickToPray.childImageSharp.fixed}
                    style={{ maxHeight: '90px', position: 'static' }}
                    imgStyle={{
                      maxHeight: '90px',
                      objectFit: 'contain',
                    }}
                    alt="clicktopray"
                  />
                </a>
                <br />
                <a
                  href="https://clicktopray.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  Click To Pray
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )}
  />
);
export default AffiliationBar;
