import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import { AllHtmlEntities } from 'html-entities';
import TitleBar from '../TitleBar/TitleBar';

import styles from './FrontPagePost.module.css';

const FrontpagePost = () => (
  <StaticQuery
    query={graphql`
      query FrontpagePostQuery {
        allWpPost(
          sort: { fields: [date], order: DESC }
          limit: 2
          filter: {
            tags: { nodes: { elemMatch: { slug: { eq: "frontpage" } } } }
          }
        ) {
          edges {
            node {
              excerpt
              title
              date(formatString: "MMMM DD, YYYY")
              slug
              categories {
                nodes {
                  name
                  slug
                }
              }
              tags {
                nodes {
                  slug
                  name
                }
              }
              id
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        src
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              content
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: posts } = data.allWpPost;

      return (
        <div>
          {posts.map(({ node: post }) => (
            <div key={post.id}>
              <TitleBar> {new AllHtmlEntities().decode(post.title)}</TitleBar>

              <Container>
                <br />
                <Row>
                  <Col sm={12} md={8}>
                    <div
                      className={styles.frontPagePost}
                      dangerouslySetInnerHTML={{
                        __html: post.content.replace(
                          new RegExp(
                            '<div id="toc-np-container">[^<]*(.*?)</div>',
                            'gm'
                          ),
                          ''
                        ),
                      }}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <a
                      href={
                        post.featuredImage.node.localFile.childImageSharp.fluid
                          .src
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Img
                        fluid={
                          post.featuredImage.node.localFile.childImageSharp
                            .fluid
                        }
                        style={{ maxHeight: '500px' }}
                        imgStyle={{ maxHeight: '500px', objectFit: 'contain' }}
                      />
                    </a>
                  </Col>
                </Row>
              </Container>
              <br />
              <br />
              <br />
            </div>
          ))}
        </div>
      );
    }}
  />
);

export default FrontpagePost;
